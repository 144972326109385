import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';


const RegisterForm = () => {
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();
  const navigate = useNavigate();
  const [cname, setCname] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileallready, setMobileallready] = useState(false);
  const [email, setEmail] = useState('');
  const [gst, setGst] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [username, setUsername] = useState('');
  const [usernameallready, setUsernameallready] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d+$/; 
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/; 

  const checkMobileNumber = async (number) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/checkusername.php?formtype=checkMobile&mobile=${number}`);
      const data = response.data;
  
      if (data.Massage === "Mobile allready Register") {
        setMobileallready(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: 'This Mobile No. Allready Register.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
        setMobileallready(false);
      }
    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };

  const checkUserName = async (loginid) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/checkusername.php?formtype=checkLoginID&loginid=${loginid}`);
      const data = response.data;
  
      if (data.Massage === "Username allready Taken") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          username: 'This Username Allready Taken.',
        }));
        setUsernameallready(true);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, username: '' }));
        setUsernameallready(false);
      }
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: 'Mobile number is required',
        }));
    } else if (!mobileRegex.test(value)){
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Mobile number must be Number Only',
      }));
    } else if (value.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Mobile number must be 10 digits',
      }));
  } else {
      checkMobileNumber(value);
    }
  };

const handleUserNameChange = (e) => {
  const value = e.target.value;
  setUsername(value);
  if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: 'Username is required',
      }));
  } else if (!passwordRegex.test(value)){
    setErrors((prevErrors) => ({
      ...prevErrors,
      username: 'Username must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a digit',
    }));
  }  else {
  checkUserName(value);
  }
    
   
  };


  const validateForm = () => {
    const newErrors = {};
    
    if (!cname) newErrors.cname = 'Company Name is required';
    if (!name) newErrors.name = 'Contact Person Name is required';
    if (!address) newErrors.address = 'Address is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';
    if (!username) {
      newErrors.username = 'Username is required';
    } else if (!passwordRegex.test(username)) {
      newErrors.username = 'Username must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a digit';
    } else if (usernameallready) {
      newErrors.username = 'This Username Allready Taken.';
    }

    if (!mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!mobileRegex.test(mobile)) {
      newErrors.mobile = 'Mobile number must be Number Only';
    }else if (mobile.length !== 10) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }else if (mobileallready) {
      newErrors.mobile = 'This Mobile No. Allready Register.';
    }
    //if (!email || !emailRegex.test(email)) newErrors.email = 'Invalid email address';
    if (!password) {
      newErrors.password = 'Password is required';
    } else if (!passwordRegex.test(password)) {
      newErrors.password = 'Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit.';
    }
    if(!confirmPassword){
      newErrors.confirmPassword = 'Confirm Password is required';
    }else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    return newErrors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
      try {
          

          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);

                setTimeout(() => {
                  setLoading(false);
                  navigate('/vendor/thanks', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Register an Account</h1>
          <form onSubmit={handleSubmit} action="https://bulkify.in/api/vendor/reg_company.php" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="cname">Company Name</label>
              <input
                type="text"
                id="cname"
                name="cname"
                value={cname}
                onChange={(e) => setCname(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, cname: !cname ? 'Company Name is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, cname: !cname ? 'Company Name is required' : '' })}
              />
              {errors.cname && <p className="error">{errors.cname}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="name">Contact Person Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, name: !name ? 'Contact Person Name is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, name: !name ? 'Contact Person Name is required' : '' })}
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={handleMobileChange}
                onBlur={handleMobileChange}
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="gst">GST No.</label>
              <input
                type="text"
                id="gst"
                name="gst"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
                
              />
              {errors.gst && <p className="error">{errors.gst}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, address: !address ? 'Address is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, address: !address ? 'Address is required' : '' })}
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, state: !state ? 'State is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, state: !state ? 'State is required' : '' })}
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="name">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, city: !city ? 'City is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, city: !city ? 'City is required' : '' })}
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="username">UserName</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={handleUserNameChange}
                onBlur={handleUserNameChange}
              />
              {errors.username && <p className="error">{errors.username}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => {
                  const password = e.target.value;
                  const passwordError = !password 
                      ? 'Password is required' 
                      : !passwordRegex.test(password) 
                      ? 'Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit.' 
                      : '';
              
                  setErrors({ ...errors, password: passwordError });
              }}
              onKeyUp={(e) => {
                  const password = e.target.value;
                  const passwordError = !password 
                      ? 'Password is required' 
                      : !passwordRegex.test(password) 
                      ? 'Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit.' 
                      : '';
              
                  setErrors({ ...errors, password: passwordError });
              }}
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyUp={(e) => {
                  const cpassword = e.target.value;
                  const cpasswordError = !cpassword 
                  ? 'Confirm Password is required' 
                  : !passwordRegex.test(cpassword) 
                  ? 'Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit.' 
                  : password !== cpassword 
                  ? 'Passwords do not match' 
                  : '';

                  setErrors({ ...errors, confirmPassword: cpasswordError });
              }}
              />
              {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
            </div>

            <button type="submit" className="btn btn1">Register</button>

            <div className="options">
              <p>Already have an account? <Link to="/vendor">Log in</Link></p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForm;
