import Header from './header';
import Footer from './footer';
import React from 'react';
import useFetchData from '../hooks/vendor/fetchuser.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { MdDelete, MdEdit } from "react-icons/md";
import axios from 'axios';
function App() {
  const { vendorSessionId, setLoading } = useAuth();
  const vid = vendorSessionId;
  const { data } = useFetchData({ vid, setLoading }); // Default to an empty array if data is undefined
  const navigate = useNavigate();
  const handleEditButtonClick = (id) => {
    navigate(`/vendor/edituser/${id}`);
  };
  const handleDelete = async (customers_id) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/delete-user.php?formtype=deleteUser&id=${customers_id}`);
      const data = response.data;
  
      if (data.Massage === "Successfully Delete") {
        window.location.reload();
      }
       
    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };



  return (
    <div className="App">
      <>
        <Header />

        <div className="midouter" id="cart">
          <div className="container">
            <div className="cart-page">
                <div className='d-flex justify-content-between'>
                    <h3>Users ({data.length})</h3>
                    <button className="btn btnn"> <Link to="/vendor/adduser" className="btn btnn">Add New</Link> </button>
                </div>
              
                {data.length > 0 && (
                <div className="table-responsive">
                  <table className="cart-table">
                    <thead>
                      <tr>
                        <th>Mobile</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Joining Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => (
                        <tr key={item.customers_id}>
                          <td>
                          <span className=" itname">{item.mobile}</span>
                          </td>
                          <td>
                            <span className=" itname">{item.name}</span>
                          </td>
                     
                          <td>
                            <span className=" itname">{item.address}</span>
                            <span className=" itname">{item.city}</span>
                            <span className=" itname">{item.state}</span>
                          </td>

                          <td>
                          <span className=" itname">{item.date_of_reg}</span>
                          </td>
                          
                          <td>
                            <span className="mgmax d-flex">
                              <button
                                className="delete me-3"
                                onClick={() => handleDelete(item.customers_id)}
                              >
                                <MdDelete />
                              </button>
                              <button
                                className="edit"
                                onClick={() => handleEditButtonClick(item.customers_id)}
                              >
                                <MdEdit />
                              </button>
                            </span>
                          </td>
                        </tr>
                      ))}
                 
                    </tbody>
                  </table>
                </div>
               )} 
             
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default App;
