import React, { useState, useRef } from "react";
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';

function Owldemo1() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const categorySliderRef = useRef(null); 

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    scrollToCategory(category);
  };

  const scrollToCategory = (category) => {
    const slider = categorySliderRef.current;
    if (slider) {

      const categoryElement = slider.querySelector(`.cat-${category}`);
      if (categoryElement) {
        const categoryRect = categoryElement.getBoundingClientRect();
        const sliderRect = slider.getBoundingClientRect();

     
        const offset = categoryRect.left - sliderRect.left - (sliderRect.width / 2) + categoryElement.offsetWidth / 2;


        slider.scrollLeft += offset;
      }
    }
  };

  const scroll = (direction) => {
    if (categorySliderRef.current) {
      const slider = categorySliderRef.current;
      const scrollAmount = 200; 
      slider.scrollLeft += direction * scrollAmount;
    }
  };

  const products = [
    { id: 1, categories: ["category-1"], name: "Category-1" },
    { id: 2, categories: ["category-2"], name: "Category 2" },
    { id: 3, categories: ["category-2"], name: "Category 2" },
    { id: 4, categories: ["category-3"], name: "Category 3" },
    { id: 5, categories: ["category-1"], name: "Category 1" },
    { id: 6, categories: ["category-2"], name: "Category 2" },
    { id: 7, categories: ["category-2"], name: "Category 2" },
    { id: 8, categories: ["category-3"], name: "Category 3" },

  ];


  const filteredProducts = selectedCategory === "all"
    ? products // Show all products if 'all' is selected
    : products.filter(product => product.categories.includes(selectedCategory) && product.name !== "All Categories");

  return (
    <div className="App">
      <>
        <Header />
        <div className="midouter">
          <div className="partners-bx mt-3">
            <div className="container">
              <div className="row title">
                <div className="bheads mhead" data-aos="zoom-in">Subcategory List of Idols (2)</div>
              </div>
            </div>
            <div className="container">
              <div className="productlist-item titem">
          
                <div className="category-slider-container">
                  <button
                    className="arrow-btn left-arrow"
                    onClick={() => scroll(-1)}
                  >
                    &#8592; 
                  </button>

                  <div className="category-slider" ref={categorySliderRef}>
                    <a 
                      className={`cat-all title ${selectedCategory === 'all' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("all")}
                    >
                         <img src="https://bulkify.in/photo/product/17300007721.jpeg" className="img-fluid" alt="" title=""/>
                      <b>All Categories</b> 
                    </a>
                    <a 
                      className={`cat-1 title ${selectedCategory === 'category-1' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("category-1")}
                    >
                         <img src="https://bulkify.in/photo/product/17300007721.jpeg" className="img-fluid" alt="" title=""/>
                      <b>Category 1</b>
                    </a>
                    <a 
                      className={`cat-2 title ${selectedCategory === 'category-2' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("category-2")}
                    >
                         <img src="https://bulkify.in/photo/product/17300007721.jpeg" className="img-fluid" alt="" title=""/>
                      <b>Category 2</b>
                    </a>
                    <a 
                      className={`cat-3 title ${selectedCategory === 'category-3' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("category-3")}
                    >
                         <img src="https://bulkify.in/photo/product/17300007721.jpeg" className="img-fluid" alt="" title=""/>
                      <b>Category 3</b> 
                    </a>
                  
                  </div>
                
                  <button
                    className="arrow-btn right-arrow"
                    onClick={() => scroll(1)}
                  >
                    &#8594; 
                  </button>
                </div>
                <div className="text-center alert">View all products</div>
                <section className="product-section productlist">
            
                  {/* Render filtered products */}
                  {filteredProducts.map(product => (
                    <div key={product.id} className={`product-item ${product.categories.join(" ")}`}>
                      <div className="productlistr">
                        {/* <small>{product.name}</small> */}
                   
                        <div className="titem item">
                          <div className="mag-img">
                            <button className="btn">
                              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"></path>
                              </svg>
                            </button> 
                            <a href="#" className="icontitle">
                              <img src="https://bulkify.in/photo/product/17300007721.jpeg" className="img-fluid" alt="" title=""/>
                            </a>
                            <a href="#" className="title"><b>Hanuman Ji</b></a>
                            <div className="dflexbox md2">
                              <div className="price"></div>
                              <div className="quantity">
                                <b>Qty.</b>
                                <select>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </div>
                            <div className="dflexbox">
                              <button className="btn"><b>Add to cart</b></button>
                              <a href="#" className="detailbtn"><b>View Detail</b></a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))}
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    </div>
  );
}

export default Owldemo1;
