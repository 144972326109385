import { useEffect, useState} from 'react';

import axios from 'axios';

const useFetchData = ({vid,catid, setLoading}) => {
  const [data, setData] = useState({});
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: response } = await axios.get(`https://bulkify.in/api/vendor/product-list.php?vid=${vid}&catid=${catid}`);
        setData(response);
      } catch (error) {
        console.error(error)
      }
      finally{
        setLoading(false);
      }
      
    };

    fetchData();
  }, [vid,catid, setLoading]);

  return {data};
};

export default useFetchData;