import { AuthProvider, useAuth  } from './hooks/AuthContext';
import Main from './main';
function App() {
    return (
      <AuthProvider>
        <Main />
      </AuthProvider>
    );
  }
  
  export default App;