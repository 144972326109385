import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; 
import useFetchData from '../hooks/vendor/fetchcontactdetail.js';
import { useAuth } from '../hooks/AuthContext';
function App() {
  const { sessionVid, setLoading } = useAuth();
  const vid = sessionVid;
  const { data } = useFetchData({vid, setLoading});


  return (
    <div className="App">
{data.length > 0 && data[0] && data[0].whatsapp && data[0].whatsapp !== '' && (



<a
        className="whatsapp"
        href={`https://wa.me/+91${data[0].whatsapp}`}
        target="_blank"
        rel="noopener noreferrer"
    >
        <FaWhatsapp /> 
    </a>

)}

    </div>
  );
}

export default App;
