import React, { useState, useEffect } from "react";
import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import Login from './login'; 
import useFetchData from '../hooks/vendor/fetchvendordetails.js';
function App() {
  const { vendorLogin, vendorSessionId,setLoading } = useAuth();
  const [showMessage, setShowMessage] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [values, setValues] = useState({
    id: vendorSessionId,
    username:"",
    name:"",
    mobile:"",
    email:"",
    cname:"",
    gst:"",
    address:"",
    city:"",
    state:"",
    password:"",
    confirmPassword:"",
  });

const vid = vendorSessionId;
  const { data } = useFetchData({ vid,setLoading});

  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        username:data[0]?.username,
        name:data[0]?.person_name,
        mobile:data[0]?.mobile,
        email:data[0]?.email,
        cname:data[0]?.company_name,
        gst:data[0]?.gst,
        address:data[0]?.address,
        city:data[0]?.city,
        state:data[0]?.state,
        password:data[0]?.password,
        confirmPassword:data[0]?.password,
      });
       
      
    }
  }, [data]);
  
  useEffect(() => {
    if (values.username.length > 0) {
      setFormValues(values);
    }
  }, [values]);


  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessage]);
 
  
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/; 
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/; 

    if (!formValues.cname) newErrors.cname = 'Company Name is required';
    if (!formValues.name) newErrors.name = 'Contact Person Name is required';
    //if (!formValues.gst) newErrors.gst = 'GSTIN is required';
    if (!formValues.address) newErrors.address = 'Address is required';
    if (!formValues.state) newErrors.state = 'State is required';
    if (!formValues.city) newErrors.city = 'City is required';
    if (!formValues.username) newErrors.username = 'Username is required';
    if (!formValues.mobile || !mobileRegex.test(formValues.mobile)) newErrors.mobile = 'Mobile number must be 10 digits';
    //if (!formValues.email || !emailRegex.test(formValues.email)) newErrors.email = 'Invalid email address';
    if (!formValues.password || !passwordRegex.test(formValues.password)) newErrors.password = 'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a digit';
    if (formValues.password !== formValues.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

    return newErrors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const checkMobileNumber = async (number) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/checkusername.php?formtype=checkMobile&mobile=${number}&id=${vid}`);
      const data = response.data;
  
      if (data.Massage === "Mobile allready Register") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: 'This Mobile No. Allready Register.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
      }
    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };
  const handleMobileChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  
    if (value.length === 10) {
      checkMobileNumber(value);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Mobile number must be 10 digits',
      }));
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
      formData.append('vid', vid);
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);
                setLoading(false);
                setShowMessage(true);

                setTimeout(() => {
                  //navigate('/pay_reg_success', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <div className="App">
     
      {vendorLogin && vendorSessionId ? (
        <>
          <Header />
          <div>
      {showMessage && <div className='successpro'>Successfully Update.</div>}
    </div>
          <div id="login">
        <div className="login-container">
          <h1>Vendor Profile</h1>
          <form onSubmit={handleSubmit} action="https://bulkify.in/api/vendor/updatevendor.php" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="cname">Company Name</label>
              <input
                type="text"
                id="cname"
                name="cname"
                value={formValues.cname}
                onChange={handleChange}
                
              />
              {errors.cname && <p className="error">{errors.cname}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="name">Contact Person Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formValues.name}
                onChange={handleChange}
                
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={formValues.mobile}
                onChange={handleMobileChange}
                
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="gst">GST No.</label>
              <input
                type="text"
                id="gst"
                name="gst"
                value={formValues.gst}
                onChange={handleChange}
                
              />
              {errors.gst && <p className="error">{errors.gst}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formValues.address}
                onChange={handleChange}
                
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formValues.state}
                onChange={handleChange}
                
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formValues.city}
                onChange={handleChange}
                
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="username">UserName</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formValues.username}
                
                
              />
              {errors.username && <p className="error">{errors.username}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
                
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={handleChange}
                
              />
              {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
            </div>

            <button type="submit" className="btn btn1">Save Change</button>
          </form>
        </div>
      </div>
          <Footer />
        </>
      ) : (
        <>
        <Login />
        </>
      )}
    </div>
  );
}

export default App;
