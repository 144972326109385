import { useState, useEffect } from 'react';

const useSession = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [sessionId, setSessionId] = useState(() => localStorage.getItem('fsessionId') || null);
  const [sessionVid, setSessionVid] = useState(() => localStorage.getItem('fsessionVid') || null);
  const [vendorSessionId, setVendorSessionId] = useState(() => localStorage.getItem('fvsessionId') || null);
  const [sessionUrl, setSessionUrl] = useState(() => localStorage.getItem('fsessionUrl') || null);
  const [cartItems, setCartItems] = useState(() => {
    const storedCart = sessionId ? localStorage.getItem(`cartItems_${sessionId}`) : null;
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const saveSessionId = (id) => {
    try {
      localStorage.setItem('fsessionId', id);
      setSessionId(id);
    } catch (error) {
      console.error('Failed to save sessionId:', error);
    }
  };
  
  const saveSessionVid = (id) => {
    try {
      localStorage.setItem('fsessionVid', id);
      setSessionVid(id);
    } catch (error) {
      console.error('Failed to save sessionVid:', error);
    }
  };
  
  
  const saveVendorSessionId = (id) => {
    try {
      localStorage.setItem('fvsessionId', id);
      setVendorSessionId(id);
    } catch (error) {
      console.error('Failed to save sessionId:', error);
    }
  };

  const saveSessionUrl = (userUrl) => {
    try {
      localStorage.setItem('fsessionUrl', userUrl);
      setSessionUrl(userUrl);
    } catch (error) {
      console.error('Failed to save sessionUrl:', error);
    }
  };

  const clearSessionId = () => {
    try {
      localStorage.removeItem('fsessionId');
      setSessionId(null);
      localStorage.removeItem(`cartItems_${sessionId}`);
      setCartItems([]);
    } catch (error) {
      console.error('Failed to clear sessionId:', error);
    }
  };

   const clearSessionVid = () => {
    try {
      localStorage.removeItem('fsessionVid');
      setSessionVid(null);
    } catch (error) {
      console.error('Failed to clear sessionVid:', error);
    }
  };

  const clearVendorSessionId = () => {
    try {
      localStorage.removeItem('fvsessionId');
      setVendorSessionId(null);
    } catch (error) {
      console.error('Failed to clear sessionId:', error);
    }
  };

  const clearSessionUrl = () => {
    try {
      localStorage.removeItem('fsessionUrl');
      setSessionUrl(null);
    } catch (error) {
      console.error('Failed to clear sessionUrl:', error);
    }
  };



  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessage]); // Re-run effect when showMessage changes

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem(`cartItems_${sessionId}`, JSON.stringify(cartItems));
    }
  }, [cartItems, sessionId]);

  
  const addItemToCart = (item, qty, price, variant, discount) => {
    // Calculate the final price after discount
    const discountedPrice = price - (price * discount / 100);
    const finalPrice = discountedPrice === 0 ? null : discountedPrice;
    //console.log('Adding item to cart:', { ...item, quantity: qty, price: finalPrice, variant });

    setCartItems(prevItems => {
        const existingItem = prevItems.find(i => i.id === item.id && i.variant === variant);
        if (existingItem) {
            // Update the quantity and discounted price of the existing item with the same variant
            return prevItems.map(i =>
                i.id === item.id && i.variant === variant 
                    ? { ...i, quantity: qty, price: finalPrice } 
                    : i
            );
        } else {
            // Add new item with specified quantity, discounted price, and variant
            return [...prevItems, { ...item, quantity: qty, price: finalPrice, variant }];
        }
    });
    
    setShowMessage(true);
};


  const updateCartItemQuantity = (id, newQuantity) => {
    setCartItems(prevItems => prevItems.map(item =>
      item.id === id ? { ...item, quantity: newQuantity } : item
    ));
  };

  const removeItemFromCart = (id) => {
    setCartItems(prevItems => prevItems.filter(item => item.id !== id));
  };

  return {
    sessionId,
    saveSessionId,
    vendorSessionId,
    saveVendorSessionId,
    clearSessionId,
    clearVendorSessionId,
    sessionVid,
    saveSessionVid,
    clearSessionVid,
    sessionUrl,
    saveSessionUrl,
    clearSessionUrl,
    cartItems,
    addItemToCart,
    updateCartItemQuantity,
    removeItemFromCart,
    showMessage
  };
};

export default useSession;
