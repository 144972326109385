import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';
function App() {

  return (
    <div className="App">
     
   
          <Header />
          <div className='content' id='thanks'>

    <div className="wrapper-2">
      <h1>Thank you !</h1>
      <p>Thank you for registration. </p>
      <p>We will review your details and approve your account. After approval you will notified and able to login on account</p>
      <Link to="/vendor" >Go Home Page...</Link>
    </div>


</div>
          <Footer />
       
     
    </div>
  );
}

export default App;
