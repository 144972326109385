import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';
import Category from './categorylist';
import UserLogin from './userlogin'; 

function App() {
  const { setLoading, login, sessionId, sessionVid, } = useAuth();

  return (
    <div className="App">
     
      {login && sessionId && sessionVid ? (
        
        <>
      
          <Header />
          <div className='midouter'>
            <Category setLoading={setLoading} vendorid={sessionVid}/>
          </div>
          <Footer />
        </>
      ) : (
        <>
        <UserLogin />
        </>
      )}
    </div>
  );
}

export default App;
