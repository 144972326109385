import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';

import Login from './login'; 
import Dashboard from './dashboard'; 

function App() {
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();

  return (
    <div className="App">
     
      {vendorLogin && vendorSessionId ? (
        <>
          <Header />
          <Dashboard   setLoading={setLoading} vid={vendorSessionId}/>
          <Footer />
        </>
      ) : (
        <>
        <Login />
        </>
      )}
    </div>
  );
}

export default App;
