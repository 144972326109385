import React from 'react';
import useFetchData from '../hooks/vendor/fetchvendordashboard.js';
import { Link } from 'react-router-dom';
import { MdPerson, MdCurrencyRupee, MdLocalShipping, MdFence } from "react-icons/md";
function Owldemo1({vid, setLoading}) {
  const { data } = useFetchData({vid, setLoading});


    return (
      
      <div className='partners-bx mt-3'>
        <div className='container'>
        <div className='cardContainer'>
        <Link to='/vendor/category-list' className="card-box"><MdFence /><div><span>{data.total_category}</span> <br></br> Total Catagory</div></Link>
        <Link to='/vendor/product-list'  className="card-box"><MdFence /><div><span>{data.total_product}</span> <br></br> Total Product</div></Link>
        <Link to='/vendor/user-list'  className="card-box"><MdPerson /><div><span>{data.total_user}</span> <br></br>Total Users</div></Link>
        <Link to='/vendor/order-list'  className="card-box"><MdLocalShipping /><div><span>{data.total_order}</span> <br></br>Total Order</div></Link>
        {/* <div className="card-box"><MdCurrencyRupee /><div><span>20</span> <br></br>Total Revenue</div></div> */}
        
        </div>
        </div>
       
      </div>
    );

}

export default Owldemo1;
