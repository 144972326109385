import React, { useState, useEffect } from "react";
import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import Login from './userlogin'; 
import useFetchData from '../hooks/fetchuserdetails.js';
function App() {
  const { login, sessionId, setLoading } = useAuth();
  const [formValues, setFormValues] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [values, setValues] = useState({
    id: sessionId,
    name:"",
    mobile:"",
    address:"",
    city:"",
    state:"",
  });

const vid = sessionId;
  const { data } = useFetchData({ vid, setLoading });

  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        name:data[0]?.name,
        mobile:data[0]?.mobile,
        address:data[0]?.address,
        city:data[0]?.city,
        state:data[0]?.state,
      });
       
      
    }
  }, [data]);
  
  useEffect(() => {
    if (values.mobile.length > 0) {
      setFormValues(values);
    }
  }, [values]);


  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessage]);
 
  
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    
    const mobileRegex = /^\d{10}$/; 
    

    if (!formValues.mobile || !mobileRegex.test(formValues.mobile)) newErrors.mobile = 'Mobile number must be 10 digits';
   
    return newErrors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
      formData.append('id', vid);
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);
                setShowMessage(true);
                setTimeout(() => {
                  //navigate('/pay_reg_success', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <div className="App">
     
      {login && sessionId ? (
        <>
          <Header />
          <div>
      {showMessage && <div className='successpro'>Successfully Update.</div>}
    </div>
          <div id="login">
        <div className="login-container">
          <h1>User Profile</h1>
          <form onSubmit={handleSubmit} action="https://bulkify.in/api/vendor/updateuser.php" method="post" encType="multipart/form-data" >
            
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formValues.name}
                onChange={handleChange}
                
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={formValues.mobile}
                onChange={handleChange}
                
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

         
            
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formValues.address}
                onChange={handleChange}
                
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formValues.state}
                onChange={handleChange}
                
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formValues.city}
                onChange={handleChange}
                
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
           

            <button type="submit" className="btn btn1">Save Change</button>
          </form>
        </div>
      </div>
          <Footer />
        </>
      ) : (
        <>
        <Login />
        </>
      )}
    </div>
  );
}

export default App;
