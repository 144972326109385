import React, { useState,useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';


const CategoryForm = ({setNewcat}) => {
    const { vendorLogin, vendorSessionId, handleLogoutVendor, setLoading } = useAuth();
    const { catid, categoryName  } = useParams();
  const [cname, setCname] = useState('');
  const [priority, setPriority] = useState('');
  const [image, setImage] = useState('');
  const [sortdsc, setSortdsc] = useState('');
  
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
    
    if (!cname) newErrors.cname = 'Sub Category Name is required';
    //if (!priority) newErrors.priority = 'Priority is required';
    //if (!image) newErrors.image = 'Image is required';
    
    
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
    formData.append('vid', vendorSessionId);
    formData.append('catid', catid);
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post('https://bulkify.in/api/vendor/add-catagory.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
              
              setNewcat(true);
            setTimeout(() => {
              navigate(`/vendor/subcategory-list/${catid}/${categoryName}`, { state: { data: dataItem } });
              setLoading(false);
            }, 500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Add New Sub Category</h1>
          <form onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="cname">Category Name</label>
              <input
                type="text"
                value={categoryName}
              />
 
            </div>
            <div className="input-group">
              <label htmlFor="cname">Sub Category Name</label>
              <input
                type="text"
                id="cname"
                name="cname"
                value={cname}
                onChange={(e) => setCname(e.target.value)}
                
              />
              {errors.cname && <p className="error">{errors.cname}</p>}
            </div>
            {/* <div className="input-group">
              <label htmlFor="priority">Priority</label>
              <input
                type="number"
                name="priority"
                id="priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                
              />
              {errors.priority && <p className="error">{errors.priority}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="image">Image</label>
              <input
                type="file"
                id="image"
                name="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                
              />
              {errors.image && <p className="error">{errors.image}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="sortdsc">Sort Description</label>
              <textarea
                
                id="sortdsc"
                name="sortdsc"
                value={sortdsc}
                onChange={(e) => setSortdsc(e.target.value)}
                
              ></textarea>
              {errors.sortdsc && <p className="error">{errors.sortdsc}</p>}
            </div> */}

           
            <button type="submit" className="btn btn1">Submit</button>

          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryForm;
