import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';
import UserLogin from './userlogin'; 
import useFetchData from '../hooks/fetchsubcategory.js';
import ProductList from './subcatproductlist.js';
import { Link } from 'react-router-dom';


function Owldemo1({catid, catname}) {
  const { setLoading, login, sessionId, sessionVid, } = useAuth();
  const { data } = useFetchData({ catid, sessionVid, setLoading });
const [catIds, setCatIds] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      setCatIds(data.map(item => item.catid));
    }
  }, [data]);


  const [selectedCategory, setSelectedCategory] = useState("all");
  const categorySliderRef = useRef(null); 

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    scrollToCategory(category);
  };

  const scrollToCategory = (category) => {
    const slider = categorySliderRef.current;
    if (slider) {

      const categoryElement = slider.querySelector(`.cat-${category}`);
      if (categoryElement) {
        const categoryRect = categoryElement.getBoundingClientRect();
        const sliderRect = slider.getBoundingClientRect();

     
        const offset = categoryRect.left - sliderRect.left - (sliderRect.width / 2) + categoryElement.offsetWidth / 2;


        slider.scrollLeft += offset;
      }
    }
  };

  const scroll = (direction) => {
    if (categorySliderRef.current) {
      const slider = categorySliderRef.current;
      const scrollAmount = 200; 
      slider.scrollLeft += direction * scrollAmount;
    }
  };

 


  






    return (
      <div className="App">
     
      {login && sessionId && sessionVid ? (
        
        <>
      
          <Header />
          <div className='midouter'>
      <div className='partners-bx mt-3'>
        <div className='container'>
          <div className="row title" style={{ marginBottom: "20px" }}>
            <div className="bheads mhead" data-aos="zoom-in">
               Subcategory List of {catname} ({data.length})
            </div>
          </div>
        </div>
        <div className='container'>
        {data.length > 0 && (
    <div className='productlist-item titem'>
      <div className="category-slider-container">
                  <button
                    className="arrow-btn left-arrow"
                    onClick={() => scroll(-1)}
                  >
                    &#8592; 
                  </button>

                  <div className="category-slider" ref={categorySliderRef}>
                  <a 
                      className={`cat-all d-flex align-items-center title ${selectedCategory === 'all' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("all")}
                    >
                      <b>All Categories</b> 
                    </a>
                  {data.map(item => (
                    <>
                   

<a 
                    key={item.catid} className={`cat-${item.catid} title ${selectedCategory === `${item.category}` ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick(`${item.category}`)}
                    >
                         <img src={item.imgpath 
      ? `https://bulkify.in/photo/category/${item.imgpath}` 
      : `https://bulkify.in/photo/product/${item.imgpath1}`} className="img-fluid" alt="" title=""/>
                      <b>{item.category}</b> 
                    </a>
                    </>
                     ))}

                  </div>
                
                  <button
                    className="arrow-btn right-arrow"
                    onClick={() => scroll(1)}
                  >
                    &#8594; 
                  </button>
                </div>
                <div className="text-center alert">{selectedCategory === 'all' ? 'VIEW All PRODUCTS' : `PRODUCTS OF ${selectedCategory}`} </div>
                  <ProductList catid={catIds} selectedCategory={selectedCategory}/>
    </div>

  )}
          
        </div>
      </div>
          <Footer />
          </div> 
        </>
      ) : (
        <>
        <UserLogin />
        </>
      )}
    </div>
    );

}

export default Owldemo1;
