import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';

const LoginForm = () => {
  const { setLoading, VendorLogin, handleLogoutVendor, vendorSessionId, setVendorLogin, saveVendorSessionId, } = useAuth();
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
 
    if (!username) {
      newErrors.username = 'Username is required';
    } 

    if (!password) {
      newErrors.password = 'Password is required';
    } 

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
      try {
          const response = await axios.post('https://bulkify.in/api/vendor/loginaction.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          
          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item
            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);
                setVendorLogin(true);
                saveVendorSessionId(dataItem.id); // Save session ID
                setTimeout(() => {
                navigate('/vendor', { state: { data: dataItem } });
        }, 1500);
            } else {
                setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } 
    }

  };

  return (
    <> 
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Log in</h1>
          <form onSubmit={handleSubmit} method="post" encType="multipart/form-data"> 
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, username: !username ? 'Username is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, username: !username ? 'Username is required' : '' })}
              />
              {errors.username && <p className="error">{errors.username}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => setErrors({ ...errors, password : !password ? 'Password is required' : '' })}
                onKeyUp={(e) => setErrors({ ...errors, password : !password ? 'Password is required' : '' })}
                
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>

            <button type="submit" className="btn btn1">Sign in</button>
            {message && <p className="error text-center">{message}</p>}
            <div className="options">
              <p>No account yet? <Link to="/vendor/register">Create an account</Link></p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginForm;
