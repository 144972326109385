// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import useSession from './useSession';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [vendorLogin, setVendorLogin] = useState(false);
  const { sessionId, saveSessionId, clearSessionId, sessionVid, saveSessionVid, clearSessionVid, sessionUrl, saveSessionUrl, clearSessionUrl,addItemToCart, cartItems, updateCartItemQuantity, removeItemFromCart,showMessage, vendorSessionId, saveVendorSessionId, clearVendorSessionId, } = useSession();

  useEffect(() => {
    if (sessionId && sessionVid) {
      setLogin(true);
    }
  }, [sessionId, sessionVid, sessionUrl]);  
  
  useEffect(() => {
    if (vendorSessionId) {
      setVendorLogin(true);
    }
  }, [vendorSessionId]);


  const handleLogout = () => {  
    clearSessionId();
    clearSessionVid();
    clearSessionUrl();
    setLogin(false);
    localStorage.setItem('Verifidemobile', '');
    
  };
const handleLogoutVendor = () => {  
  clearVendorSessionId();
    setVendorLogin(false);
  };

  return (
    <AuthContext.Provider value={{ login, setLogin, saveSessionId, saveSessionVid, saveSessionUrl, handleLogout, loading, setLoading, sessionId, sessionVid, sessionUrl, addItemToCart, cartItems, updateCartItemQuantity, removeItemFromCart,showMessage, vendorLogin, setVendorLogin, vendorSessionId, saveVendorSessionId, clearVendorSessionId, handleLogoutVendor }}>
      {children}
    </AuthContext.Provider>
  );
};
