import Header from './header';
import Footer from './footer';
import React, { useState } from 'react';
import useFetchData from '../hooks/fetchorders.js';
import { Link, useNavigate } from 'react-router-dom';
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import { MdDelete, MdEdit } from "react-icons/md";
import axios from 'axios';
function App() {
  const { login, sessionId, setLoading } = useAuth();
  const userid = sessionId;
  const { data } = useFetchData({ userid, setLoading }); // Default to an empty array if data is undefined
  const navigate = useNavigate();
  const handleimgClick = (orderid) => {
    navigate(`/order-detail/${orderid}`);
  };



  return (
    <div className="App">
      <>
        <Header />

        <div className="midouter" id="cart">
          <div className="container">
            <div className="cart-page">
                <div className='d-flex justify-content-between'>
                    <h3>Previous Orders ({data.length})</h3>
                   
                </div>
              
                {data.length > 0 && (
                <div className="table-responsive">
                  <table className="cart-table">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        {data.some(item => item.total_amount) != 0.00  && (
                        <th>Amount</th>
                        )}
                        <th>Total Item</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => (
                        <tr key={item.order_id} style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                          <td>
                          <span className=" itname">{item.order_id}</span>
                          </td>
                          <td>
                            <span className=" itname">{item.added_date}</span>
                          </td>
                          {item.total_amount != 0.00  && (
                          <td>
                            <span className=" itname">{item.total_amount}</span>
                          </td>
                          )}
                          <td>
                          <span className=" itname">{item.totalitem}</span>
                          </td>
                         
                        </tr>
                      ))}
                 
                    </tbody>
                  </table>
                </div>
               )} 
             
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default App;
