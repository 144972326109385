import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData =  ({ vid, setLoading } = {})=> {
  const [data, setData] = useState({});
  

  useEffect(() => {
    if (!vid || !setLoading) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(`https://bulkify.in/api/vendor/vendor-details.php?vid=${vid}`);
        setData(response);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (vid) {
      fetchData();
    }
  }, [vid, setLoading]);

  return {
    data
  };
};

export default useFetchData;
