import React, { useState, useEffect } from "react";
import { useAuth } from '../hooks/AuthContext';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import Login from './login'; 
import { useNavigate } from 'react-router-dom';
function App({setNewusr}) {
 const { vendorLogin, vendorSessionId } = useAuth();

 const [name, setName] = useState('');
 const [mobile, setMobile] = useState('');
 const [address, setAddress] = useState('');
 const [state, setState] = useState('');
 const [city, setCity] = useState('');
 const navigate = useNavigate();
const vid = vendorSessionId;


  
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^\d{10}$/; 


    if (!mobile || !mobileRegex.test(mobile)) newErrors.mobile = 'Mobile number must be 10 digits';
    //if (!email || !emailRegex.test(email)) newErrors.email = 'Invalid email address';


    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
      formData.append('vid', vid);
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
              setNewusr(true);
                navigate('/vendor/user-list');
            }else if (dataItem.Massage === "Allready") {
                //setMessage(dataItem.Massage);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <div className="App">
     
     {vendorLogin && vendorSessionId ? (
        <>
          <Header />
          <div id="login">
        <div className="login-container">
          <h1>Add User</h1>
          <form onSubmit={handleSubmit} action="https://bulkify.in/api/vendor/add-user.php" method="post" encType="multipart/form-data" >
            
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

         
            
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
           

            <button type="submit" className="btn btn1">Save </button>
          </form>
        </div>
      </div>
          <Footer />
        </>
      ) : (
        <>
        <Login />
        </>
      )}
    </div>
  );
}

export default App;
