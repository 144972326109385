import React, { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { useAuth } from '../hooks/AuthContext';
function App() {
    const { login, sessionId, sessionVid, cartItems = [], updateCartItemQuantity, removeItemFromCart } = useAuth();

    useEffect(() => {
       
        const removeAllItems = () => {
            cartItems.forEach(item => removeItemFromCart(item.id)); // Assuming each item has a unique id
        };

        removeAllItems();
    }, [cartItems, removeItemFromCart]); 

  return (
    <div className="App">
     
   
          <Header />
          <div className='content' id='thanks'>

    <div className="wrapper-2">
      <h1>Thank you !</h1>
      <p>Thank you for Order. </p>
      <p>We will review your order details. </p>

    </div>


</div>
          <Footer />
       
     
    </div>
  );
}

export default App;
