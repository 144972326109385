import Header from './header';
import Footer from './footer';
import React, { useState } from 'react';
import useFetchData from '../hooks/vendor/fetchorderdetail.js';
import { Link } from 'react-router-dom';
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import { MdDelete, MdEdit } from "react-icons/md";

function App({orderid}) {
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();
  const { data } = useFetchData({ orderid, setLoading }); // Default to an empty array if data is undefined


  const handleDelete = (catid) => {
    // Implement your delete logic here
    console.log(`Delete item with id: ${catid}`);
  };



  return (
    <div className="App">
      <>
       
    
    <Header />
      <div className='previousorder-bx mt-3'>
      <div className="container ">
  <div className="bheads mhead"> Order Detail  </div>

  {data.length > 0 && (
  <div className="previous-orders-container">
     {data.map(order  => (
                       
    <div className="order-card" key={order.order_id}>

    <div className="products-list">   <b>Order ID: {order.order_id}</b>
    <b>Date: {order.added_date}</b> 
    {order.total_amount!= 0.00 && (
    <b>Amount: {order.total_amount}</b> 
    )}
    {order.payment!= 'null' && (
    <b>Payment Method : {order.payment}</b> 
    )}
    {order.txn_number!= 'null' && (
    <b>Transaction Id: {order.txn_number}</b> 
    )}
    <b>User Mob.: {order.usermob}</b> </div>
    {order.image!= '' && (
   <a 
   href={`https://bulkify.in/photo/category/${order.image}`} 
   download={order.image} 
   onClick={(e) => e.stopPropagation()} // Prevents other onclick events if needed
 >
   <img
     src={`https://bulkify.in/photo/category/${order.image}`}
     alt={order.image}
     className="product-image"
   />
 </a>
        )}
      <div className="products-list">
      {Array.isArray(order.basket) && (
                order .basket.map((item, index) => (
                  <div className="product-item" key={index}>
                    <img
                      src={`https://bulkify.in/photo/product/${item.imgpath1}`}
                      alt={item.product_name}
                      className="product-image"
                    />
                    <div className="product-info">
                      <p className="product-name">{item.product_name}</p>
                      {item.price!='' && (
                      <p className="product-price"><b><FaRupeeSign />{item.total_final_price} </b>
                      {item.discount > 0 && (
                        <>
                      &nbsp; ({item.discount}% off) &nbsp;<span style={item.discount > 0 ? { textDecoration: "line-through" } : {}} ><FaRupeeSign />{item.price}</span>
                      </>
                      )}
                      </p>
                      )}

                      <p className="product-quantity">Quantity: {item.quantity}</p>
                    </div>
                  </div>
                ))
              ) }
      </div>


    </div>
     ))}
   
  </div>
  )}
</div>

    
    
    
      </div>
            <Footer />
    
      </>
    </div>
  );
}

export default App;
