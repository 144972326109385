import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/vendor/fetchcategory.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { MdDelete, MdEdit } from "react-icons/md";
import Login from './login'; 
import axios from 'axios';

function App() {
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();
  const [showMessagealert, setShowMessagealert] = useState(false);
  const vid = vendorSessionId;
  const { data } = useFetchData({ vid, setLoading }); // Default to an empty array if data is undefined
  const navigate = useNavigate();

  useEffect(() => {
    if (showMessagealert) {
      const timer = setTimeout(() => {
        setShowMessagealert(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessagealert]);


  const handleDelete = async (catid) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/delete-catagory.php?formtype=deleteCategory&catid=${catid}`);
      const data = response.data;
  
      if (data.Massage === "Successfully Delete") {
        window.location.reload();
      }
       if (data.Massage === "Delete Product") {
        setShowMessagealert(true);
      } 
    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };

  const handleEditButtonClick = (id) => {
    navigate(`/vendor/editcategory/${id}`);
  };
  const handleimgClick = (url) => {
    navigate(`/vendor/${url}`);
  };

  return (
    <div className="App">
      {vendorLogin && vendorSessionId ? (
      <>
        <Header />
        <div>
      {showMessagealert && <div className='alertpro'>Please Delete Product First </div>}
    </div>
        <div className="midouter" id="cart">
          <div className="container">
            <div className="cart-page">
                <div className='d-flex justify-content-between'>
                    <h3>Main Categories ({data.length})</h3>
                    <button className="btn btnn"> <Link to="/vendor/addcategory" className="btn btnn">Add New</Link> </button>
                </div>
              
                {data.length > 0 ? (
                <div className="table-responsive">
                  <table className="cart-table">
                    <thead>
                      <tr>
                        {/* <th>Image</th> */}
                        <th>Category Name</th>
                        {/* <th>Priority</th> */}
                        <th>Sub Category</th> 
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => (
                        <tr key={item.catid}>
                          {/* <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.url)}>
                            <img onClick={() => handleimgClick(item.url)}
                              src={`https://www.pakwaancandlelightdinner.com/new_app/photo/category/${item.imgpath}`}
                              alt={item.catagory}
                              className="product-image"
                            />
                          </td> */}
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.url)}>
                            <span className=" itname">{item.category}</span>
                          </td>
                         {/*  <td>
                            <span className=" itname">{item.sno}</span>
                          </td> */}
                          <td>
                          <Link to={`/vendor/subcategory-list/${item.catid}/${item.category}`}>
                              <span className="badge badge-info">
                                View Sub Category - {item.subcat}
                              </span>
                              </Link>
                          </td> 
                      
                          <td>
                            <span className="mgmax d-flex">
                              <button
                                className="delete me-3"
                                onClick={() => handleDelete(item.catid)}
                              >
                                <MdDelete />
                              </button>
                              <button
                                className="edit"
                                onClick={() => handleEditButtonClick(item.catid)}
                              >
                                <MdEdit />
                              </button>
                            </span>
                          </td>
                        </tr>
                      ))}
                 
                    </tbody>
                  </table>
                </div>
               ):(
                <div className="table-responsive">
                <table className="cart-table">
                  <thead>
                    <tr>
                      {/* <th>Image</th> */}
                      <th>Category Name</th>
                      {/* <th>Priority</th> */}
                      <th>Sub Category</th>
                      {/* <th>Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                          <td colSpan="3">
                            NO DATA FOUND
                          </td>
                        </tr>
                  </tbody>
                  </table>
                </div>
               )} 
             
            </div>
          </div>
        </div>
        <Footer />
      </>
       ) : (
        <>
        <Login />
        </>
      )}
    </div>
  );
}

export default App;
